import React from "react";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import airpodTrackerMobile from "../../assets/images/airpodTrackerMobile.svg";
import airpodTracker from "../../assets/images/airpodTracker.svg";
import infoBackground from "../../assets/images/infoBackground.svg";
import infoBackgroundMobile from "../../assets/images/infoBackgroundMobile.svg";
import Airpodcontrol from "./Airpodcontrol";
import ListOfDevices from "./ListOfDevices";

const TrackerInfo = () => {
  return (
    <div className="relative">
      <div className="mx-[1rem] mb-[3.146rem] mt-[3.381rem] bg-white py-[3.75rem] rounded-[0.938rem] relative z-10 md:-mt-[2.5rem] lg:mx-[2.5rem] lg:mb-[2rem] lg:h-[43.668rem] xl:h-[48.668rem] lg:overflow-hidden">
        <img
          src={airpodTrackerMobile}
          alt="Airpod tracker"
          className="mx-auto w-[16.083rem] mb-[3.688rem] lg:hidden"
        />
        <img
          src={airpodTracker}
          alt="Airpod tracker"
          className="hidden lg:absolute lg:block lg:left-[0.979rem] lg:bottom-0 lg:w-[20.668rem] xl:left-[5.958rem] xl:w-[24.668rem] 2xl:left-[11.938rem]"
        />
        <div className="w-[17.188rem] mx-auto flex flex-col space-y-[1.875rem] lg:absolute lg:left-[27.258rem] lg:w-[20rem] lg:top-[17.08rem] lg:z-10 xl:left-[36.911rem] 2xl:left-[45.563rem] 2xl:w-[26rem] 3xl:w-[36rem]">
          <AnimatedOnScroll
            animationIn="fadeInUp"
            animationInDuration={800}
            className="font-[600] text-[1.9rem] leading-[2rem] 2xl:text-[3.5rem] 2xl:leading-[100%]"
          >
            <h1>
              <span>{`Track `}</span>
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-green-600 to-green-400">{`your nearby devices `}</span>
              <span>{`instantly`}</span>
            </h1>
          </AnimatedOnScroll>

          <AnimatedOnScroll
            animationIn="fadeInUp"
            animationInDuration={800}
            className="text-[#86868B] text-[1.188rem] leading-[1.438rem] font-[600]"
          >
            <p>
              Track your AirPods in real time. Never lose your AirPods again
              with our easy to use app. See all your nearby devices within 10
              seconds using your bluetooth connection only. Add as many devices
              as you want.
            </p>
          </AnimatedOnScroll>
        </div>
        <img
          className="hidden lg:absolute lg:block lg:w-[45%] lg:bottom-0 lg:right-0 lg:z-0 xl:w-[45%] 2xl:w-[40%]"
          src={infoBackground}
          alt="Info Background"
        />
      </div>

      <img
        className="absolute w-[100%] top-[23.813rem] right-0 z-1 lg:hidden"
        src={infoBackgroundMobile}
        alt="Info Background"
      />

      <Airpodcontrol />
      <ListOfDevices />
    </div>
  );
};

export default TrackerInfo;
