import React from "react";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import { Link } from "react-router-dom";
import appIcon from "../../assets/images/appIcon.svg";
import downloadButton from "../../assets/images/downloadButton.svg";
import footerBackground from "../../assets/images/footerBackground.png";

const Footer = () => {
  return (
    <div className="relative mx-auto mt-[8.625rem] flex flex-col items-center text-center lg:mt-[16.188rem]">
      <div className=" flex flex-col items-center text-center">
        <img
          className="relative z-10 w-[5.594rem] h-[5.594rem] mb-[1.03rem] md:w-[6rem] md:h-[6rem] lg:w-[6.6rem] lg:h-[6.6rem] xl:w-[7.3rem] xl:h-[7.3rem] 2xl:w-[8.094rem] 2xl:h-[8.094rem] 2xl:mb-[2.219rem]"
          src={appIcon}
          alt="Device Tracker Logo"
        />
        <img
          className="absolute max-w-[150%] -mt-[7rem] z-0 xs:-mt-[9rem] xs:max-w-[120%] sm:-mt-[11rem] sm:max-w-[100%] md:-mt-[10rem] md:max-w-[80%] lg:-mt-[14rem] lg:max-w-[70%] xl:-mt-[12rem] xl:max-w-[60%] 2xl:-mt-[20rem] 2xl:max-w-[70%]"
          src={footerBackground}
          alt="Footer Background"
        />
        <h1 className="relative z-10 footerHeaderStretch text-[#171716] text-[2.125rem] leading-[2.5rem] font-[600] mb-[1.001rem] lg:tracking-wide 2xl:text-[5.875rem] 2xl:leading-[5.563rem] 2xl:mb-[2.5rem]">
          <AnimatedOnScroll
            animationIn="fadeInUp"
            animationInDuration={800}
          >{`Download Airpod `}</AnimatedOnScroll>
          <AnimatedOnScroll
            animationIn="fadeInUp"
            animationInDuration={800}
          >{`Tracker from the`}</AnimatedOnScroll>
          <AnimatedOnScroll
            animationIn="fadeInUp"
            animationInDuration={800}
          >{`App Store`}</AnimatedOnScroll>
        </h1>
        <AnimatedOnScroll
          animationIn="fadeInUp"
          animationInDuration={800}
          className="relative z-10 text-[#2E2F2B] text-[0.8rem] leading-[1.25rem] font-[400] w-[16.563rem] mb-[1.875rem] 2xl:text-[1.188rem] 2xl:w-[28.438rem] 2xl:leading-[1.438rem] 2xl:mb-[3.438rem]"
        >
          <p>
            Install AirPod Tracker for iOS and iPad today and start tracking for
            free!
          </p>
        </AnimatedOnScroll>

        <a href="https://apps.apple.com/us/app/air-finder-find-my-device/id6451196878" target="_blank" rel="noopener noreferrer">
          <img
            className="relative z-10 w-[12.75rem] h-[4.938rem] mb-[6.563rem] 2xl:w-[18.313rem] 2xl:h-[5.813rem] 2xl:mb-[6.563rem]"
            src={downloadButton}
            alt="Download"
          />
        </a>
      </div>

      <div className="font-sf-pro-text relative z-10 text-[#5B5E56] font-[400] text-[0.625rem] leading-[0.75rem] flex space-x-[2.188rem] tracking-[0.02em] mb-[2rem] 2xl:text-[0.875rem] 2xl:leading-[1.063rem]">
        <Link to="#">Terms of Use</Link>
        <Link to="#">FAQ</Link>
        <Link to="#">Roadmap</Link>
        <Link to="#">Support</Link>
        <Link to="#">Privacy Policy</Link>
      </div>
    </div>
  );
};

export default Footer;
