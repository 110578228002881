import React from "react";
import decreaseButton from "../../assets/images/decreaseButton.svg";

const LeftArrow = ({ onClick }) => {
  return (
    <button onClick={() => onClick()}>
      <img
        className="w-[1.563rem] h-[1.563rem] absolute left-0 top-[47%]"
        src={decreaseButton}
        alt="Decrease Button"
      />
    </button>
  );
};

export default LeftArrow;
