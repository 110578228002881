import React from "react";
import Hero from "../components/Home/Hero";
import AirpodLocation from "../components/Home/AirpodLocation";
import LocationTracker from "../components/Home/LocationTracker";
import TrackerInfo from "../components/Home/TrackerInfo";
import ChargingCase from "../components/Home/ChargingCase";
import Reviews from "../components/Home/Reviews";
import TipsTricks from "../components/Home/TipsTricks";

const Home = () => {
  return (
    <div>
      <Hero />
      <AirpodLocation />
      <LocationTracker />
      <TrackerInfo />
      <ChargingCase />
      <Reviews />
      <TipsTricks />
    </div>
  );
};

export default Home;
