import React from "react";
import location from "../../assets/images/location.svg";
import locationMobile from "../../assets/images/locationMobile.webp";

const LocationTracker = () => {
  return (
    <div className="relative">
      <div
        className="hidden md:block md:mx-auto md:bg-center md:h-[80.55rem] md:bg-no-repeat md:mt-[12.5rem]"
        style={{
          backgroundImage: `url(${location})`,
        }}
      ></div>
      <img
        src={locationMobile}
        alt="location"
        className="w-full mt-[5.902rem] md:hidden"
      />
    </div>
  );
};

export default LocationTracker;
