import React, { useState } from "react";
import { Link } from "react-router-dom";
import menu from "../../assets/icons/menu.svg";
import appIcon from "../../assets/images/appIcon.svg";
import menus from "../../utils/menus";
import SingleMenu from "./SingleMenu";
import downloadIcon from "../../assets/icons/downloadIcon.svg";

const Header = () => {
  const [isClicked, setIsClicked] = useState(false);
  const clickHandler = (e) => setIsClicked(!isClicked);

  return (
    <>
      <div className="h-[7.688rem] bg-[#FBFBFB] relative md:h-[4.25rem] md:static md:flex md:justify-center md:items-center z-20">
        <div className="absolute top-[3.625rem] left-[0.813rem] w-full md:static">
          <div className="flex justify-between md:justify-around md:items-center">
            <div className="flex space-x-[1.25rem] items-center">
              <Link to="/">
                <img
                  className="w-[2.188rem] h-[2.188rem]"
                  src={appIcon}
                  alt="Airpod Tracker"
                />
              </Link>
              <Link to="/">
                <span className="font-sf-pro-text text-[#2E2F2B] font-[600] text-[1rem] leading-[1.188rem] -tracking-[0.02]">
                  Airpod Tracker
                </span>
              </Link>
            </div>
            <button className="pr-[1.188rem] md:hidden" onClick={clickHandler}>
              <img src={menu} alt="Menu Button" />
            </button>
            <div className="hidden md:flex md:justify-center md:items-center space-x-[2.5rem]">
              <ul className="font-sf-pro-text text-[0.75rem] text-[#5B5E56] font-[400] leading-[1.063rem] flex space-x-[2.5rem]">
                {menus.map((menu) => {
                  return <SingleMenu key={menu.id} menu={menu} />;
                })}
              </ul>
              <div className="w-[7rem] h-[2.063rem] bg-[#171716] rounded-[1.25rem] py-[0.5rem] px-[1rem]">
                  <a href="https://apps.apple.com/us/app/air-finder-find-my-device/id6451196878" className="flex items-center"> 
                    <img
                      src={downloadIcon}
                      alt="Download"
                      className="w-[1rem] h-[1rem]"
                    />
                    <span className="font-sf-pro-text text-[0.75rem] text-white leading-[1.0673rem] font-[400]">
                      Download
                    </span>
                  </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isClicked && (
        <ul className="font-sf-pro-text text-[0.75rem] text-[#5B5E56] font-[400] leading-[1.063rem] bg-[#FBFBFB] flex flex-col space-y-[0.5rem] p-4 md:hidden">
          {menus.map((menu) => {
            return <SingleMenu key={menu.id} menu={menu} />;
          })}
        </ul>
      )}
    </>
  );
};

export default Header;
