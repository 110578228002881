import React from "react";

const SingleMenu = ({ menu }) => {
  return (
    <li className="text-[#171716] text-[0.8rem] leading-[1.063rem] font-[400] cursor-pointer p-2 hover:bg-green-200 hover:rounded-lg md:text-[#171716] md:text-[0.75rem] md:leading-[1.063rem] md:font-[400] md:cursor-pointer">
      <a href={menu.url}>{menu.name}</a>
    </li>
  );
};

export default SingleMenu;
