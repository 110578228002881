import React from "react";
import increaseButton from "../../assets/images/increaseButton.svg";

const RightArrow = ({ onClick }) => {
  return (
    <button onClick={() => onClick()}>
      <img
        className="w-[1.563rem] h-[1.563rem] absolute right-0 top-[47%]"
        src={increaseButton}
        alt="Increase Button"
      />
    </button>
  );
};

export default RightArrow;
