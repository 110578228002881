import React from "react";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import devicesMobile from "../../assets/images/devicesMobile.svg";
import devices from "../../assets/images/devices.svg";
import devicesBackground from "../../assets/images/devicesBackground.svg";
import devicesEarphone from "../../assets/images/devicesEarphone.webp";

const ListOfDevices = () => {
  return (
    <div className="mx-[1rem] mt-4 bg-white py-[3.75rem] rounded-[0.938rem] relative z-20 lg:mx-[2.5rem] lg:mb-[2rem] lg:h-[43.668rem] xl:h-[48.668rem] lg:overflow-hidden">
      <img
        src={devicesMobile}
        alt="Airpod tracker"
        className="mx-auto w-[16.083rem] mb-[3.688rem] lg:hidden"
      />
      <img
        src={devices}
        alt="Airpod tracker"
        className="hidden lg:absolute lg:block lg:left-[0.979rem] lg:bottom-0 lg:w-[20.668rem] lg:z-20 xl:w-[24.668rem] xl:left-[5.958rem] 2xl:left-[11.938rem]"
      />
      <div className="w-[17.188rem] mx-auto flex flex-col space-y-[1.875rem] lg:absolute lg:w-[20rem] lg:left-[27.258rem] lg:top-[17.0215rem] lg:z-20 xl:left-[36.911rem] 2xl:left-[45.563rem] 2xl:w-[26rem] 3xl:w-[36rem]">
        <AnimatedOnScroll
          animationIn="fadeInUp"
          animationInDuration={800}
          className="font-[600] text-[1.9rem] leading-[2rem] 2xl:text-[3.5rem] 2xl:leading-[100%]"
        >
          <h1>
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-green-600">{`Link your `}</span>
            <span>{`family member's AirPods`}</span>
          </h1>
        </AnimatedOnScroll>

        <AnimatedOnScroll
          animationIn="fadeInUp"
          animationInDuration={800}
          className="text-[#86868B] text-[1.188rem] leading-[1.438rem] font-[600]"
        >
          <p>
            Stay connected to your loved ones with our AirPod tracker! No more
            worrying about lost AirPods or trying to keep track of multiple
            apps. Simply connect each family member's AirPods to the app and
            you'll be able to locate them at any time.
          </p>
        </AnimatedOnScroll>
      </div>
      <img
        className="hidden lg:absolute lg:block lg:bottom-0 lg:left-[15%] lg:z-10"
        src={devicesBackground}
        alt="Devices Background"
      />
      <img
        className="hidden lg:absolute lg:block lg:top-0 lg:right-[6rem] lg:z-0 lg:w-[10.813rem] lg:h-[20.625rem] xl:right-[8.313rem] xl:w-[11.813rem] xl:h-[27.625rem]"
        src={devicesEarphone}
        alt="Devices Earphone"
      />
    </div>
  );
};

export default ListOfDevices;
