import React from "react";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import airpodControlMobile from "../../assets/images/airpodControlMobile.svg";
import airpodControl from "../../assets/images/airpodControl.svg";
import controlBackground from "../../assets/images/controlBackground.svg";
import controlEarphone from "../../assets/images/controlEarphone.webp";

const Airpodcontrol = () => {
  return (
    <div className="mx-[1rem] mb-[3.146rem] mt-4 bg-white py-[3.75rem] rounded-[0.938rem] relative z-10 lg:mx-[2.5rem] lg:mb-[2rem] lg:h-[43.668rem] xl:h-[48.668rem] lg:overflow-hidden">
      <img
        src={airpodControlMobile}
        alt="Airpod tracker"
        className="mx-auto w-[16.083rem] mb-[3.688rem] lg:hidden"
      />
      <img
        src={airpodControl}
        alt="Airpod tracker"
        className="hidden lg:absolute lg:block lg:left-[0.979rem] lg:bottom-0 lg:w-[20.668rem] lg:z-20 xl:w-[24.668rem] xl:left-[5.958rem] 2xl:left-[11.938rem]"
      />
      <div className="w-[17.188rem] mx-auto flex flex-col space-y-[1.875rem] lg:absolute lg:w-[20rem] lg:left-[27.258rem] lg:top-[19.5rem] lg:z-20 xl:left-[36.911rem] 2xl:left-[45.563rem] 2xl:w-[26rem] 3xl:w-[36rem]">
        <AnimatedOnScroll
          animationIn="fadeInUp"
          animationInDuration={800}
          className="font-[600] text-[1.9rem] leading-[2rem] 2xl:text-[3.5rem] 2xl:leading-[100%]"
        >
          <h1>
            <span>{`Get a distance `}</span>
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-cyan-500 via-sky-600 to-green-400">{`alert `}</span>
          </h1>
        </AnimatedOnScroll>

        <AnimatedOnScroll
          animationIn="fadeInUp"
          animationInDuration={800}
          className="text-[#86868B] text-[1.188rem] leading-[1.438rem] font-[600]"
        >
          <p>
            Never misplace your AirPods again. Simply connect your AirPods to
            the app and receive a sound alert when you are 80% near your device.
          </p>
        </AnimatedOnScroll>
      </div>
      <img
        className="hidden lg:absolute lg:block lg:bottom-0 lg:left-0 lg:z-10"
        src={controlBackground}
        alt="Control Background"
      />
      <img
        className="hidden lg:absolute lg:block lg:bottom-0 lg:right-0 lg:z-0 lg:w-[20.75rem] lg:h-[30.375rem] xl:w-[25.75rem] xl:h-[37.375rem]"
        src={controlEarphone}
        alt="Control Earphone"
      />
    </div>
  );
};

export default Airpodcontrol;
