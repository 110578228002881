import React from "react";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import heroBackground from "../../assets/images/heroBackground.png";
import overviewHeroAirpodsLeft from "../../assets/images/overviewHeroAirpodsLeft.png";
import overviewHeroAirpodsRight from "../../assets/images/overviewHeroAirpodsRight.png";
import downloadButton from "../../assets/images/downloadButton.svg";

import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="flex justify-center items-center relative my-[1.5rem] sm:my-[2rem] md:my-[2.5rem] lg:my-[3.2rem] xl:my-[3.9rem] 2xl:my-[4.625rem]">
      <div className="absolute">
        <img
          src={heroBackground}
          alt="Hero Background"
          className="w-[100%] relative z-0"
        />
      </div>

      <div className="w-[25.063rem] h-[26.725rem] relative sm:w-[37.5254rem] sm:h-[31.83rem] md:w-[49.9878rem] md:h-[36.935rem] lg:w-[62.4502rem] lg:h-[42.04rem] xl:w-[74.9126rem] xl:h-[47.145rem] 2xl:w-[87.375rem] 2xl:h-[52.25rem]">
        <img
          src={overviewHeroAirpodsLeft}
          alt="Airpod Left"
          className="leftAnm absolute top:0 left-0 w-[7.938rem] h-[13.627] sm:w-[8.973rem] sm:h-[16.1516rem] md:w-[10.548rem] md:h-[18.6762rem] lg:w-[12.123rem] lg:h-[21.2008rem] xl:w-[13.698rem] xl:h-[23.7254rem] 2xl:w-[15.813rem] 2xl:h-[26.25rem]"
        />
        <img
          src={overviewHeroAirpodsRight}
          alt="Airpod Right"
          className="rightAnm absolute bottom-0 right-0 w-[7.938rem] h-[13.627] sm:w-[8.973rem] sm:h-[16.1516rem] md:w-[10.548rem] md:h-[18.6762rem] lg:w-[12.123rem] lg:h-[21.2008rem] xl:w-[13.698rem] xl:h-[23.7254rem] 2xl:w-[15.813rem] 2xl:h-[26.25rem]"
        />
        <div className="absolute w-full h-full flex flex-col space-y-[4.125rem] justify-center items-center xl:space-y-[3.438rem]">
          <div className="w-[16.5rem] h-[10rem] text-center z-10 mt-[6rem] sm:w-[25.85rem] sm:h-[14.5rem] md:w-[35.2rem] md:h-[19rem] lg:w-[44.55rem] lg:h-[23.5remrem] xl:w-[53.9rem] xl:h-[28rem] 2xl:w-[63.25rem] 2xl:h-[32.5rem]">
            <h1 className="text-[2.125rem] leading-[2.5rem] font-[600] -tracking-[0.04rem] text-center sm:text-[3.125rem] sm:leading-[100%] md:text-[4.3rem] lg:text-[5.4rem] xl:text-[6.5rem] 2xl:text-[7.5rem] 2xl:tracking-[0.1rem]">
              <AnimatedOnScroll
                animationIn="fadeInUp"
                animationInDuration={800}
              >
                {`Use the top-rated `}
              </AnimatedOnScroll>
              <AnimatedOnScroll
                animationIn="fadeInUp"
                animationInDuration={800}
              >
                {`AirPod tracking `}
              </AnimatedOnScroll>
              <AnimatedOnScroll
                animationIn="fadeInUp"
                animationInDuration={800}
              >
                {`app to protect `}
              </AnimatedOnScroll>
              <AnimatedOnScroll
                animationIn="fadeInUp"
                animationInDuration={800}
              >
                {`your airpods`}
              </AnimatedOnScroll>
            </h1>
          </div>
          <a href="https://apps.apple.com/us/app/air-finder-find-my-device/id6451196878" target="_blank" rel="noopener noreferrer">
            <img src={downloadButton} alt="Download Button" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
