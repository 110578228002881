import React from "react";
import { Link } from "react-router-dom";
import shareCopying from "../../assets/images/shareCopying.png";
import shareExtension from "../../assets/images/shareExtension.png";
import shareFacebook from "../../assets/images/shareFacebook.png";
import shareLinkedin from "../../assets/images/shareLinkedin.png";

const Share = () => {
  return (
    <>
      <div className="font-[600] text-[1rem] leading-[1.188rem] text-[#3A3C43] -tracking-[0.02em] self-end">
        Share :
      </div>
      <div className="flex space-x-[0.625rem]">
        <Link to="#">
          <img src={shareCopying} alt="Copy" height="22" />
        </Link>
        <Link to="#">
          <img src={shareExtension} alt="Extension" height="22" />
        </Link>
        <Link to="#">
          <img src={shareFacebook} alt="Facebook" height="22" />
        </Link>
        <Link to="#">
          <img src={shareLinkedin} alt="Linkedin" height="22" />
        </Link>
      </div>
    </>
  );
};

export default Share;
