import React from "react";
import FindTipTrick from "./FindTipTrick";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import LeftArrow from "./LeftArrow";
import RightArrow from "./RightArrow";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1279 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1279, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const TipsTricksLarge = ({ tipsTricks }) => {
  const tips = Array.from(
    { length: tipsTricks.length },
    (_, index) => index + 1
  );

  return (
    <div className="relative mx-[1.063rem] my-0 xl:block xl:w-[53rem] 2xl:w-[55rem] 3xl:w-[63rem]">
      <Carousel
        responsive={responsive}
        autoPlay={true}
        interval={2000}
        transitionDuration={500}
        infinite={true}
        customLeftArrow={<LeftArrow />}
        customRightArrow={<RightArrow />}
      >
        {tips.map((tip) => {
          return (
            <FindTipTrick currentTip={tip} key={tip} tipsTricks={tipsTricks} />
          );
        })}
      </Carousel>
    </div>
  );
};

export default TipsTricksLarge;
