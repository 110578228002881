import { Routes, Route } from "react-router-dom";
import Header from "./components/Header/Index";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import Footer from "./components/Footer/Index";
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <div className="bg-[#F4F4F3] h-full font-sf-pro-display">
      <header className="sticky top-0 z-[1000]">
        <Header />
      </header>

      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path={`/blogs/:blogId`} element={<Blog />} exact />
        </Routes>
      </ScrollToTop>

      <footer className="overflow-hidden">
        <Footer />
      </footer>
    </div>
  );
}

export default App;
