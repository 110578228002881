import React from "react";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import track from "../../assets/icons/track.svg";
import history from "../../assets/icons/history.svg";
import search from "../../assets/icons/search.svg";

const AirpodLocation = () => {
  return (
    <div className="relative z-20 mt-[7.025rem] bg-white w-[20.375rem] m-auto p-[3.75rem] rounded-[0.938rem] flex flex-col space-y-[6.25rem] sm:mx-[1rem] sm:w-auto xl:m-auto xl:mt-0 xl:flex-row xl:justify-around xl:space-y-0 xl:mx-[2.5rem] 2xl:pt-[8.875rem] 2xl:pl-[8.875rem] 2xl:pb-[10.313rem] 2xl:pr-[11.375rem] 2xl:rounded-[2.188rem] 3xl:pl-[11.875rem] 3xl:pr-[14.375rem]">
      <AnimatedOnScroll
        animationIn="fadeInUp"
        animationInDuration={800}
        className="location-heading m-auto w-[12.875rem] font-[600] text-[2rem] leading-[100%] xl:text-[3.5rem] xl:w-[21.375rem]"
      >
        <h1>{`Use the app to monitor the location and activity of your AirPods`}</h1>
      </AnimatedOnScroll>

      <ul className="m-auto flex flex-col space-y-[3.75rem] xl:flex-row xl:space-y-0 xl:space-x-[4rem] 3xl:space-x-[6.625rem]">
        <li className="flex flex-col w-[12.875rem]">
          <AnimatedOnScroll animationIn="fadeInUp" animationInDuration={800}>
            <img
              src={track}
              alt="Use the app to locate your AirPod"
              className="w-[1rem] h-[1rem]"
            />
          </AnimatedOnScroll>
          <AnimatedOnScroll animationIn="fadeInUp" animationInDuration={800}>
            <h1 className="text-[#2E2F2B] font-[600] text-[1.313rem] leading-[1.313rem] mt-[1.125rem]">
              <span>Use the app to locate your AirPod</span>
            </h1>
          </AnimatedOnScroll>
          <AnimatedOnScroll animationIn="fadeInUp" animationInDuration={800}>
            <p className="font-sf-pro-text text-[#2E2F2B] font-[400] text-[0.875rem] leading-[1.225rem] mt-[1.5rem] xl:mt-[2.813rem]">
              The app shows real-time location of AirPods on an easy to
              understand map.
            </p>
          </AnimatedOnScroll>
        </li>
        <li className="flex flex-col w-[12.875rem]">
          <AnimatedOnScroll animationIn="fadeInUp" animationInDuration={800}>
            <img
              src={history}
              alt="Use the app to locate your AirPod"
              className="w-[1rem] h-[1rem]"
            />
          </AnimatedOnScroll>
          <AnimatedOnScroll animationIn="fadeInUp" animationInDuration={800}>
            <h1 className="text-[#2E2F2B] font-[600] text-[1.313rem] leading-[1.313rem] mt-[1.125rem]">
              <span>
                Use the app to view the past locations of your AirPods
              </span>
            </h1>
          </AnimatedOnScroll>
          <AnimatedOnScroll animationIn="fadeInUp" animationInDuration={800}>
            <p className="font-sf-pro-text text-[#2E2F2B] font-[400] text-[0.875rem] leading-[1.225rem] mt-[1.5rem] xl:mt-[1.5rem]">
              The AirPod tracker app can show you where your AirPods have been,
              simply download the app and check it.
            </p>
          </AnimatedOnScroll>
        </li>
        <li className="flex flex-col w-[12.875rem]">
          <AnimatedOnScroll animationIn="fadeInUp" animationInDuration={800}>
            <img
              src={search}
              alt="Use the app to locate your AirPod"
              className="w-[1rem] h-[1rem]"
            />
          </AnimatedOnScroll>
          <AnimatedOnScroll animationIn="fadeInUp" animationInDuration={800}>
            <h1 className="text-[#2E2F2B] font-[600] text-[1.313rem] leading-[1.313rem] mt-[1.125rem]">
              <span>The App identifies suspicious AirPod locations</span>
            </h1>
          </AnimatedOnScroll>
          <AnimatedOnScroll animationIn="fadeInUp" animationInDuration={800}>
            <p className="font-sf-pro-text text-[#2E2F2B] font-[400] text-[0.875rem] leading-[1.225rem] mt-[1.5rem] xl:mt-[1.5rem]">
              Use the airpod tracker to find out if your AirPods are frequently
              visiting suspicious locations.
            </p>
          </AnimatedOnScroll>
        </li>
      </ul>
    </div>
  );
};

export default AirpodLocation;