const menus = [
  {
    id: 1,
    name: "Overview",
    url: "#",
  },
  {
    id: 2,
    name: "Features",
    url: "#",
  },
  {
    id: 3,
    name: "Why Us?",
    url: "#",
  },
  {
    id: 4,
    name: "News",
    url: "#",
  },
  {
    id: 5,
    name: "Contact",
    url: "#",
  },
];

export default menus;
