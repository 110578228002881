import React from "react";
import { Link } from "react-router-dom";

const SingleTipTrick = ({ singleTip }) => {
  const { heading, description, image } = singleTip;
  let desc = <span>{description}</span>;

  if (description.length > 63) {
    desc = (
      <span>
        {description.slice(0, 63)}
        <Link to={`/blogs/${singleTip.id}`} className="font-[700]">
          ...Read More
        </Link>
      </span>
    );
  }

  return (
    <div className="w-auto mx-auto">
      <div className="flex flex-col space-y-[0.938rem] xl:grid xl:grid-cols-2 xl:items-center mx-[2.5rem] xl:gap-x-[0.5rem]">
        <img
          className="mx-auto w-[15.188rem] xl:order-last"
          src={image}
          alt={heading}
        />

        <div className="flex flex-col w-[11.313rem] h-fit space-y-[0.938rem] text-left mx-auto">
          <Link to={`/blogs/${singleTip.id}`}>
            <h1 className="text-[1.313rem] text-[#2E2F2B] font-[600] w-[7.75rem] leading-[1.313rem] h-[4.1rem] overflow-hidden">
              {heading.length > 30
                ? `${heading.slice(0, 27)}...`
                : `${heading}`}
            </h1>
          </Link>

          <p className="font-sf-pro-text text-[0.875rem] leading-[1.225rem] font-[400] h-fit text-[#2E2F2B]">
            {desc}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SingleTipTrick;
