import React from "react";
import { useParams } from "react-router-dom";
import Hero from "../components/Blog/Hero";
import Share from "../components/Blog/Share";
import Breadcrumbs from "../components/Breadcrumbs";
import TipsTricks from "../components/Home/TipsTricks";
import tipsTricks from "../utils/tipsTricks";

const Blog = () => {
  const { blogId } = useParams();
  const blog = tipsTricks.find((tip) => tip.id === parseInt(blogId));
  const breadcrumbs = [
    {
      value: "Homepage",
      link: "/",
    },
    { value: "Blogs", link: "/blogs" },
  ];

  const content = `<main style="font-size: 16px; font-weight: 400; line-height: 26px; color: #575757">
              <section>
                Buying and selling is a fundamental activity in human society.
                From the earliest days of human civilization, people have
                engaged in trade, exchanging goods and services with each other
                to satisfy their needs and desires. Today, buying and selling is
                more complex than ever before, with a variety of options
                available to consumers and businesses alike. In this blog, we
                will explore the various aspects of buying and selling,
                including the different types of markets, the factors that
                influence prices, and the different strategies that buyers and
                sellers can use to maximize their profits.
              </section>
              <br />
              <section>
                <h3 style="font-weight: 700">Types of Markets:</h3>
                <br />
                <p>
                  There are two main types of markets: the physical market and
                  the virtual market. The physical market refers to a physical
                  location where buyers and sellers come together to exchange
                  goods and services. Examples of physical markets include
                  shopping malls, farmers markets, and flea markets. The virtual
                  market, on the other hand, refers to an online marketplace
                  where buyers and sellers can trade goods and services over the
                  internet. Examples of virtual markets include Amazon, eBay,
                  and Alibaba.
                </p>
                <br />
              </section>
              <section>
                <p>
                  There are several factors that influence the prices of goods
                  and services in the market. These factors include:
                </p>
                <br />
                <ol style="list-style-type: decimal; list-style-position: inside">
                  <li>
                    Supply and demand: This is the most fundamental factor that
                    affects prices. When demand for a product is high and the
                    supply is low, the price of the product will increase.
                    Conversely, when the supply is high and the demand is low,
                    the price will decrease.
                  </li>
                  <li>
                    Production costs: The cost of producing a product is another
                    factor that affects its price. When the cost of production
                    increases, the price of the product will also increase.
                  </li>
                  <li>
                    Competition: The level of competition in the market also
                    affects prices. When there is intense competition in the
                    market, sellers will have to lower their prices to remain
                    competitive.
                  </li>
                  <li>
                    Government policies: Government policies such as taxes and
                    subsidies can also affect prices. For example, if the
                    government imposes a tax on a particular product, the price
                    of that product will increase.
                  </li>
                </ol>
                <br />
              </section>
              <section>
                <p>Strategies for Buyers:</p>
                <br />
                <p>
                  Buyers can use several strategies to maximize their purchasing
                  power and get the best value for their money. Some of these
                  strategies include:
                </p>
                <ol style="list-style-type: decimal; list-style-position: inside">
                  <li>
                    Comparison shopping: Comparison shopping involves comparing
                    the prices and features of similar products before making a
                    purchase. This can help buyers find the best value for their
                    money.
                  </li>
                  <li>
                    Negotiation: Buyers can also negotiate with sellers to get a
                    lower price. This is particularly useful when buying
                    high-ticket items such as cars or houses.
                  </li>
                  <li>
                    Buying in bulk: Buying in bulk can also help buyers save
                    money. This is because sellers often offer discounts for
                    bulk purchases.
                  </li>
                  <li>
                    Timing: Buying at the right time can also help buyers save
                    money. For example, buying off-season items during the
                    off-season can often result in lower prices.
                  </li>
                </ol>
                <br />
              </section>
              <section>
                <p>Strategies for Sellers:</p>
                <br />
                <p>
                  Sellers can also use several strategies to maximize their
                  profits and sell their products at the highest price possible.
                  Some of these strategies include:
                </p>
                <ol style="list-style-type: decimal; list-style-position: inside">
                  <li>
                    Pricing strategies: Sellers can use various pricing
                    strategies to attract buyers and maximize their profits. For
                    example, they can use psychological pricing, where they set
                    the price just below a round number, such as $9.99 instead
                    of $10.
                  </li>
                  <li>
                    Product differentiation: Sellers can also differentiate
                    their products from their competitors to attract buyers.
                    This can be done by offering unique features or packaging.
                  </li>
                  <li>
                    Advertising: Advertising is another important strategy for
                    sellers. By advertising their products, sellers can create
                    awareness and generate interest among potential buyers.
                  </li>
                  <li>
                    Customer service: Providing excellent customer service is
                    also important for sellers. This can help build trust and
                    loyalty among customers, which can lead to repeat business.
                  </li>
                </ol>
                <br />
              </section>
              <section>
                <p>Conclusion:</p>
                <br />
                <p>
                  Buying and selling is an essential activity in human society.
                  Whether we are buying groceries or selling real estate, we all
                  participate in the market in some way. Understanding the
                  different types of markets, the factors that influence prices,
                  and the
                </p>
              </section>
            </main>`;

  return (
    <>
      <div className="px-[1rem]">
        <div className="max-w-[91.125rem] w-full mx-auto relative z-[100]">
          <div className="flex justify-between mt-[1rem] mb-[1.25rem] md:mt-[4rem] md:mb-[2.25rem]">
            <Breadcrumbs breadcrumbs={breadcrumbs} heading={blog.heading} />
            <div className="hidden md:flex md:mr-[1.313rem] md:space-x-[1.25rem] md:font-inter">
              <Share />
            </div>
          </div>
          <Hero heading={blog.heading} />
          <div className="flex ml-[0.625rem] mt-[1.25rem] space-x-[1.25rem] font-inter mb-[2.5rem] md:hidden">
            <Share />
          </div>
          <div
            className="max-w-[75.75rem] mx-auto mb-[2.5rem] font-inter md:mb-[5rem]"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
      </div>

      <TipsTricks />
    </>
  );
};

export default Blog;
