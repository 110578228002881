import React from "react";
import reviewers from "../../utils/reviewers";
import SingleReviewer from "./SingleReviewer";

const Reviews = () => {
  return (
    <div className="my-[8.125rem] mx-[3.563rem] xl:my-[15.625rem] xl:mx-0">
      <ul className="flex flex-col space-y-[6.25rem] items-center xl:flex-row xl:justify-around xl:space-y-0 xl:items-start">
        {reviewers.map((reviewer) => {
          return <SingleReviewer key={reviewer.id} reviewer={reviewer} />;
        })}
      </ul>
    </div>
  );
};

export default Reviews;
