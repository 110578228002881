import React from "react";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import tipsTricks from "../../utils/tipsTricks";
import TipsTricksLarge from "./TipsTricksLarge";

const TipsTricks = () => {
  return (
    <div className="bg-white mx-[1.063rem] rounded-[0.938rem] py-[3.75rem] flex flex-col space-y-[2.5rem] xl:flex-row xl:justify-around xl:space-y-0 xl:py-[8.063rem] xl:mx-[2.5rem] 3xl:px-[11.875rem]">
      <AnimatedOnScroll animationIn="fadeInUp" animationInDuration={800}>
        <h1 className="location-heading mx-auto w-[17.75rem] font-[600] text-[2rem] text-center leading-[100%] xl:hidden">
          {`AirPod Tips and Tricks`}
        </h1>
        <h1 className="hidden xl:block xl:location-heading xl:font-[600] xl:leading-[100%] xl:mx-0 xl:text-[3.5rem]">
          <span>AirPod Tips</span>
          <br />
          <span>and Tricks</span>
        </h1>
      </AnimatedOnScroll>

      <TipsTricksLarge tipsTricks={tipsTricks} />
    </div>
  );
};

export default TipsTricks;