import React from "react";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import aPerson from "../../assets/images/aPerson.webp";
import chargingCase from "../../assets/images/chargingCase.webp";

const ChargingCase = () => {
  return (
    <div className="mt-[1.896rem] mx-[1rem] flex flex-col space-y-[1.292rem] lg:mx-[2.5rem] lg:flex-row lg:justify-between lg:space-y-0 lg:space-x-[1.509rem] lg:h-fit">
      <img
        src={aPerson}
        alt="A person"
        className="rounded-xl lg:max-w-[49.2%] lg:w-full h-fit object-contain"
      />
      <div className="relative bg-white rounded-xl h-[27.563rem] flex flex-col items-center lg:w-[49.5%] lg:h-auto">
        <AnimatedOnScroll
          className="absolute mx-auto top-[4rem] text-[0.875rem] leading-[1.25rem] w-[17rem] text-[#86868B] font-[600] xl:w-[29rem] xl:top-[15%] xl:text-[1.188rem] xl:font-[700] xl:leading-[1.438rem] 2xl:w-[36.688rem] 2xl:top-[20%]"
          animationIn="fadeInUp"
          animationInDuration={800}
        >
          <p>
            The AirPods' Lightning and MagSafe Charging Cases provide up to 30
            hours of listening time and the MagSafe Charging Case can be easily
            charged with MagSafe or other wireless charging options.
          </p>
        </AnimatedOnScroll>

        <img
          className="absolute bottom-0 mx-auto w-[11.813rem] xl:w-[15.438rem] 2xl:w-[23.25rem]"
          src={chargingCase}
          alt="Charging Case"
        />
      </div>
    </div>
  );
};

export default ChargingCase;
