import tipsEarphone from "../assets/images/tipsEarphone.png";
import tipsMobile from "../assets/images/tipsMobile.png";

const tipsTricks = [
  {
    id: 1,
    heading: "How to clean your AirPod",
    description:
      "Clean AirPods with dry cotton swab on microphone and mesh speaker. Clean charging case with a dry, lint-free cloth or a dampen cloth with isopropyl alcohol if needed.",
    image: tipsEarphone,
  },
  {
    id: 2,
    heading: "Check the battery level of your AirPods",
    description:
      "Add battery widget to iPhone for AirPod battery check. Long press on home screen, tap + sign, select Batteries from widgets, drag to home screen. Check AirPod battery level anytime in use.",
    image: tipsMobile,
  },
  {
    id: 3,
    heading: "Tap your AirPod for playback control",
    description:
      "Use built-in controls on AirPods to control music/podcasts without using iPhone. Quickly pause/skip tracks, control varies by AirPod model.",
    image: tipsEarphone,
  },
  {
    id: 4,
    heading: "Use “Hey Siri!”",
    description:
      'Use "Hey Siri" on 2nd-gen and newer AirPods to control music, send messages, change volume, ask for weather updates. 1st-gen AirPods trigger Siri by double-tapping earbuds.',
    image: tipsMobile,
  },
];

export default tipsTricks;
