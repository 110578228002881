import React from "react";
import { AnimatedOnScroll } from "react-animated-css-onscroll";

const SingleReviewer = ({ reviewer }) => {
  return (
    <li className="flex flex-col space-y-[1.25rem] w-[18.75rem] items-center">
      <img src={reviewer.image} alt={reviewer.username} />
      <AnimatedOnScroll animationIn="fadeInUp" animationInDuration={800}>
        <span className="text-[1.25rem] leading-[1.5rem] font-[700] text-[#171716]">
          {reviewer.username}
        </span>
      </AnimatedOnScroll>

      <AnimatedOnScroll
        animationIn="fadeInUp"
        animationInDuration={800}
        className="font-sf-pro-text text-[0.875rem] leading-[1.25rem] font-[400] text-black text-center"
      >
        <p>{reviewer.info}</p>
      </AnimatedOnScroll>
    </li>
  );
};

export default SingleReviewer;
