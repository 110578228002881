import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ breadcrumbs, heading }) => {
  return (
    <div className="w-full flex self-end ml-[0.563rem] font-inter text-[0.875rem] font-[400] leading-[1.063rem] md:w-fit">
      <ul className="flex text-[#2E2F2B]">
        {breadcrumbs.map((breadcrumb, index) => (
          <li key={index}>
            <Link to={breadcrumb.link}>{breadcrumb.value}</Link>
            <span>&nbsp;{`>`}&nbsp;</span>
          </li>
        ))}
      </ul>
      <span className="text-[#4F8117] truncate">
        {heading.length > 30 ? `${heading.substring(0, 30)}...` : heading}
      </span>
    </div>
  );
};

export default Breadcrumbs;
