import React from "react";
import blogHero from "../../assets/images/blogHero.png";
import blogBackground from "../../assets/images/blogBackground.png";
import blogBackgroundMobile from "../../assets/images/blogBackgroundMobile.png";

const Hero = ({ heading }) => {
  return (
    <div className="relative flex items-center justify-center md:mb-[8.125rem]">
      <img
        src={blogHero}
        alt="Hero"
        className="aspect-[382/289] object-cover rounded-[1.563rem] md:aspect-[486/199]"
      />
      <div className="absolute max-w-[90%] md:max-w-[70%]">
        <h1 className="font-sf-pro-text font-[600] text-[2rem] leading-[100%] text-center text-[#FBFBFB] opacity-[0.8] sm:text-[4rem] xl:text-[6rem] 2xl:text-[8.125rem]">
          {heading}
        </h1>
      </div>
      <div className="hidden absolute -z-[10] md:block">
        <img src={blogBackground} alt="Background" />
      </div>
      <div className="absolute -z-[10] md:hidden">
        <img src={blogBackgroundMobile} alt="Background" />
      </div>
    </div>
  );
};

export default Hero;
