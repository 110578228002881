import kevinTrel from "../assets/images/kevinTrel.svg";
import patelJoal from "../assets/images/patelJoal.svg";
import alexGarlin from "../assets/images/alexGarlin.svg";

const reviewers = [
  {
    id: 1,
    username: "@kevintrel65",
    image: kevinTrel,
    info: "I recently tried the AirPods Tracker app and was impressed by its features.The interface is user-friendly and easy to navigate. The app provides the option to play a sound on the missing earbuds to help locate them. I highly recommend this app for anyone looking to quickly and easily find their misplaced AirPods.",
  },
  {
    id: 2,
    username: "@pateljoal645",
    image: patelJoal,
    info: "This app is very convenient to track down misplaced AirPods. The app utilizes the location of my iPhone to pinpoint the location of my AirPods. I found the app to be very reliable in helping me locate my AirPods quickly.",
  },
  {
    id: 3,
    username: "@alexgarlin",
    image: alexGarlin,
    info: "The app allows multiple users to connect to the same account, making it easy for everyone in the family to locate their own AirPods. This was especially helpful for my kids who would often misplace their AirPods in different parts of the house. Overall, I highly recommend this app for any family looking for a reliable and efficient way to keep track of multiple AirPods.",
  },
];

export default reviewers;
